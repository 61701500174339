import { createGlobalState } from "react-hooks-global-state";

export type PinStateType = "top" | "prepared" | "pinned" | "unpinned";

export interface IState {
  isSignupPopupOpen: boolean;
  isDownloadPopupOpen: boolean;
  headerOptions: {
    colorScheme: "dark" | "light";
    hidden: boolean;
    pinState: PinStateType;
  };
  utmTags?: string;
}

const initialState: IState = {
  isSignupPopupOpen: false,
  isDownloadPopupOpen: false,
  utmTags: undefined,
  headerOptions: {
    colorScheme: "dark",
    hidden: false,
    pinState: "top",
  },
};
const { useGlobalState } = createGlobalState(initialState);

export { useGlobalState };
