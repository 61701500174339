import React from "react";
import appstoreBadge from "../../static/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googleplayBadge from "../../static/google-play-badge.svg";

export interface DownloadButtonsProps {
  className?: string;
  buttonClassName?: string;
  onClick: () => void;
}

const DownloadButtons: React.FC<DownloadButtonsProps> = ({
  className,
  buttonClassName = "h-12",
  onClick,
}) => {
  return (
    <div className={className}>
      <button
        type="button"
        aria-label="Open download popup"
        className="flex space-x-4"
        onClick={onClick}
      >
        <img
          src={appstoreBadge}
          alt="Download on the App Store"
          className={buttonClassName}
        />
        <img
          src={googleplayBadge}
          alt="Get it on the play store"
          className={buttonClassName}
        />
      </button>
    </div>
  );
};

export default DownloadButtons;
