import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import useFormAnalytics from "../hooks/useFormAnalytics";

const SignupForm: React.FC = () => {
  const tracker = useFormAnalytics();
  const { t } = useTranslation(["sign_up_form"]);

  return (
    <form
      ref={tracker}
      name="signup"
      method="POST"
      action="https://one.us2.list-manage.com/subscribe/post?u=631c5c381beca770a6717b876&amp;id=d457e2233b"
      target="_blank"
      className="flex justify-center w-full"
    >
      <input type="hidden" name="form-name" value="signup" />
      <div className="flex flex-col w-full max-w-lg space-y-4 md:flex-row md:space-y-0">
        <label className="w-full">
          <span className="sr-only">{t("sign_up_form:email_label")}</span>
          <input
            type="text"
            name="EMAIL"
            className="w-full px-6 py-3 text-sm placeholder-gray-700 bg-gray-200 border-none rounded-full form-input md:rounded-r-none"
            placeholder={t("sign_up_form:email_placeholder")}
          />
        </label>
        <button
          type="submit"
          value={"Submit"}
          name="subscribe"
          className="flex-shrink-0 px-12 py-3 text-sm font-bold tracking-wide text-white bg-green-500 rounded-full font-headline md:rounded-l-none"
        >
          {t("sign_up_form:submit")}
        </button>
      </div>
      <div className="absolute" style={{ left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_631c5c381beca770a6717b876_d457e2233b"
          tabIndex={-1}
          value=""
          readOnly
        />
      </div>
    </form>
  );
};

export default SignupForm;
