import { Transition } from "@headlessui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";

type Props = {
  onClick: () => void;
};

export default function FloatingSignupFab(props: Props) {
  const { t } = useTranslation(["navigation"]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const onScroll = _.throttle(() => {
      setVisible(window.pageYOffset > window.innerHeight);
    }, 250);

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [setVisible]);

  return (
    <Transition
      show={visible}
      enter="duration-300 ease-out"
      enterFrom="opacity-0 scale-95 translate-y-8"
      enterTo="opacity-100 scale-100 translate-y-0"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100 translate-y-0"
      leaveTo="opacity-0 scale-95 translate-y-4"
      className="fixed bottom-0 left-0 right-0 w-full transition-all transform safe-bottom md:hidden"
    >
      <div className="w-full px-2 py-4">
        <button
          onClick={props.onClick}
          className="w-full px-6 py-2 mr-2 text-sm font-medium tracking-widest text-white uppercase bg-green-500 rounded-lg hover:shadow-md"
        >
          {t("navigation:fab")}
        </button>
      </div>
    </Transition>
  );
}
