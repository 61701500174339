import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";
import { ISiteMetaData } from "../types/gatsby";
import DownloadButtons from "./DownloadButtons";
import LogoType from "./LogoType";

const query = graphql`
  {
    site {
      siteMetadata {
        downloadActive
        mailContact
      }
    }
    bCorpBadge: file(
      relativePath: {
        eq: "images/certificates/certified-pending-bcorp-logo.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 128
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 20 }
        )
      }
    }
  }
`;

export interface IFooterQueryData {
  site: {
    siteMetadata: ISiteMetaData;
  };
  bCorpBadge: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

const Footer: React.FC = () => {
  const { t } = useTranslation(["common", "footer"]);

  const {
    site: {
      siteMetadata: { mailContact, downloadActive },
    },
    bCorpBadge,
  }: IFooterQueryData = useStaticQuery(query);

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  const [utmTags] = useGlobalState("utmTags");

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  return (
    <footer className="bg-gray-200 border-t">
      <div className="container-7xl">
        <div className="grid grid-cols-12 py-12 gap-x-6 gap-y-10">
          <div className="flex items-center col-span-12 space-x-4 sm:items-start">
            <LogoType
              className="color-primary"
              style={{ width: "120px", height: "30px" }}
            />
          </div>

          <ul className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2">
            <li className="mb-2 text-sm font-bold text-gray-500 font-headline">
              {t("footer:about.title")}
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/">{t("footer:about.link1")}</Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/mission">{t("footer:about.link2")}</Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/faq">{t("footer:about.link6")}</Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/security">{t("footer:about.link7")}</Link>
            </li>
            {/* <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/mission/#team">{t("footer:about.link3")}</Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/mission/#jobs">{t("footer:about.link4")}</Link>
            </li> */}
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <a href="PLAN3T_Press_Kit.zip">{t("footer:about.link5")}</a>
            </li>
            {/* <li className="py-1 text-sm">
              <Link to="/">About us</Link>
            </li> */}
          </ul>

          <ul className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2">
            <li className="mb-2 text-sm font-bold text-gray-500 font-headline">
              {t("footer:sustainability.title")}
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/blog">{t("footer:sustainability.link1")}</Link>
            </li>
            {/* <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/become-a-partner">{t("footer:sustainability.link2")}</Link>
            </li> */}
          </ul>

          <ul className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2">
            <li className="mb-2 text-sm font-bold text-gray-500 font-headline">
              {t("footer:companies.title")}
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/our-partners">{t("footer:companies.link1")}</Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/become-a-partner/#brands">
                {t("footer:companies.link2")}
              </Link>
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <Link to="/become-a-partner/#organizations">
                {t("footer:companies.link3")}
              </Link>
            </li>
          </ul>

          <ul className="col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-2">
            <li className="mb-2 text-sm font-bold text-gray-500 font-headline">
              {t("footer:contact.title")}
            </li>
            <li className="py-1 text-sm text-gray-500 md:font-normal">
              <a href={`mailto:${mailContact}`}>{mailContact}</a>
            </li>
          </ul>

          <div className="col-span-12 text-center sm:col-span-6 sm:text-left lg:col-span-2 lg:text-right">
            <GatsbyImage
              image={bCorpBadge.childImageSharp.gatsbyImageData}
              alt="PLAN3T is a Pending B Corporation"
              className="flex-shrink-0 w-12"
            />
          </div>

          {downloadActive ? (
            <div className="col-span-12 text-center sm:col-span-6 sm:text-right lg:text-right lg:col-span-2">
              <a
                className="button-green-filled md:hidden"
                href={`/download${
                  utmTags && utmTags !== "" ? "?" : ""
                }${utmTags}`}
              >
                {t("common:download.general")}
              </a>

              <DownloadButtons
                className="hidden md:inline-block"
                buttonClassName="sm:h-8 md:h-8 lg:h-6"
                onClick={() => {
                  setIsDownloadPopupOpen(true);
                }}
              />
            </div>
          ) : (
            <>
              <button
                onClick={handleOpenSignupPopup}
                className="w-full px-10 py-3 text-sm font-medium tracking-wide text-white uppercase bg-green-500 rounded-lg md:hidden hover:shadow-md font-headline"
              >
                {t("common:download.general")}
              </button>

              <DownloadButtons
                buttonClassName="sm:h-8 md:h-8 lg:h-6"
                onClick={handleOpenSignupPopup}
              />
            </>
          )}
        </div>

        <div className="flex flex-col flex-wrap items-center py-4 mb-8 space-x-0 space-y-4 text-sm text-gray-500 border-t border-gray-300 sm:flex-row sm:justify-between md:mb-0 sm:space-y-0 sm:space-x-4">
          <span className="flex-grow">
            &copy; {new Date().getFullYear()} {t("common:company.title")}
          </span>

          {/* <div>
            <LanguageSwitch />
          </div> */}

          <ul className="flex flex-row space-x-4">
            <li>
              <Link to="/datenschutzerklaerung">
                {t("common:privacy.privacy_policy")}
              </Link>
            </li>
            <li>
              <Link to="/impressum">{t("common:imprint")}</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
