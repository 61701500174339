require("./src/css/tailwind.css");
require("@fontsource/rubik/400.css");
require("@fontsource/rubik/600.css");
require("@fontsource/rubik/900.css");

// exports.onRouteUpdate = function () {
//     if (process.env.NODE_ENV === `production` && typeof window.fbq === `function`) {
//         window.fbq("track", "ViewContent");
//     }
// };
