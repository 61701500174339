import { MutableRefObject, useEffect, useRef } from "react";

export default function useFormAnalytics(): MutableRefObject<HTMLFormElement | null> {
  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // @ts-ignore
    if (!ref.current || typeof window._paq === "undefined") {
      return;
    }

    const f = ref.current;

    function listener() {
      const name = f.name;
      // @ts-ignore
      _paq.push(["trackEvent", "Form", "Submit", name]);
    }

    f.addEventListener("submit", listener);

    return () => f.removeEventListener("submit", listener);
  }, [ref]);
  return ref;
}
