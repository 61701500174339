import {Transition} from "@headlessui/react";
import {Link, useTranslation} from "gatsby-plugin-react-i18next";
import * as React from "react";
import {useState} from "react";
import useCookieState from "../hooks/useCookieState";
import Portal from "./Portal";
import {Helmet} from "react-helmet";

const tiktokscript = `
!function (w, d, t) {
    w.TiktokAnalyticsObject = t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

    // ttq.load('CA8EQ0BC77U9LQHLKEEG'); -> consent banner
    // ttq.page(); -> consent banner
}(window, document, 'ttq');
`;

export default function ConsentDialog() {
    const {t} = useTranslation(["consent"]);

    const [statusString, setStatus] = useCookieState("cookie-consent-status", "");

    const needsBanner = statusString !== null && (statusString.length ?? 0) === 0;

    const [items, setItems] = useState({
        required: true,
        stats: false,
        marketing: false,
    } as Record<string, boolean>);

    const _trigger = (selection: typeof items) => {
        try {
            // @ts-ignore
            if (selection.stats && !!window._paq && !!window._paq.push) {
                // @ts-ignore
                window._paq.push(["setConsentGiven"]);
            }
            // @ts-ignore
            if (selection.marketing && !!window.fbq) {
                // @ts-ignore
                window.fbq("consent", "grant");
            }
            // @ts-ignore
            if (selection.marketing && !!window.ttq) {
                // @ts-ignore
                ttq.load('CA8EQ0BC77U9LQHLKEEG');
                // @ts-ignore
                ttq.page();
            }
        } finally {
            setStatus(JSON.stringify(selection));
        }
    };

    const onAcceptAll = () => {
        setItems({
            required: true,
            stats: true,
            marketing: true,
        });
        _trigger({
            required: true,
            stats: true,
            marketing: true,
        });
    };

    const onSave = () => _trigger(items);

    return (
        <>
            <Helmet>
                <script>{tiktokscript}</script>
            </Helmet>
            <Portal>
                <Transition
                    show={needsBanner}
                    enter="ease-out duration-500"
                    enterFrom="opacity-0 scale-98 translate-y-2"
                    enterTo="opacity-100 scale-100 translate-y-0"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-2"
                    className="fixed inset-x-0 bottom-0 z-50 transition transform sm:pb-4"
                >
                    <div className="px-0 mx-auto max-w-8xl sm:px-4 lg:px-6">
                        <div className="p-6 bg-white sheet-shadow rounded-xl">
                            <div className="flex flex-col items-start justify-between">
                                <div className="flex items-center w-full mb-3">
                                    <p className="mb-2 text-xs text-gray-900 sm:mb-0">
                                        {t("body")}
                                        &nbsp;
                                        <Link to="/datenschutzerklaerung" className="text-green-500">
                                            {t("common:privacy.privacy_policy")}
                                        </Link>
                                    </p>
                                </div>

                                <div
                                    className="flex flex-col items-center justify-between w-full space-y-2 sm:flex-row sm:space-y-0">
                                    <ul className="flex flex-row mb-3 space-x-6 md:space-x-8 sm:mb-0">
                                        {Object.keys(items).map((item) => (
                                            <li
                                                key={item}
                                                className={
                                                    "flex items-start" +
                                                    (item === "required"
                                                        ? " opacity-50 pointer-events-none"
                                                        : "")
                                                }
                                            >
                                                <div className="flex items-center h-5">
                                                    <input
                                                        id={"cookie_consent_" + item}
                                                        name={item}
                                                        type="checkbox"
                                                        disabled={item === "required"}
                                                        className="w-4 h-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                                                        checked={items[item]}
                                                        onChange={(e) =>
                                                            setItems({
                                                                ...items,
                                                                [item]: e.target.checked,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="ml-2 text-sm">
                                                    <label
                                                        htmlFor={"cookie_consent_" + item}
                                                        className="text-xs font-medium text-gray-700"
                                                    >
                                                        {t("types." + item)}
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="flex flex-row space-x-6">
                                        <button
                                            onClick={onSave}
                                            className="!text-xs !px-2 !py-1 button-blue-ghost"
                                        >
                                            {t("accept_save")}
                                        </button>
                                        <button
                                            onClick={onAcceptAll}
                                            className="!text-xs !px-4 !py-2 button-green-filled"
                                        >
                                            {t("accept_all")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </Portal>
        </>
    );
}
