import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { useGlobalState } from "../state/globalState";
import { ISiteMetaData } from "../types/gatsby";
import FloatingSignupFab from "./FloatingSignupFab";
import LanguageSwitch from "./LanguageSwitch";
import LogoType from "./LogoType";

const query = graphql`
  {
    site {
      siteMetadata {
        mailContact
        downloadActive
      }
    }
  }
`;

export interface NavigationQueryData {
  site: {
    siteMetadata: ISiteMetaData;
  };
}

export interface NavigationProps {
  className?: string;
  showSignupFab: boolean;
}

const Navigation: React.FC<NavigationProps> = ({
  className,
  showSignupFab,
}) => {
  const { t } = useTranslation(["navigation"]);
  const {
    site: {
      siteMetadata: { mailContact, downloadActive },
    },
  }: NavigationQueryData = useStaticQuery(query);

  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );

  const [utmTags] = useGlobalState("utmTags");

  const [isMobileNavigationOpen, setIsMobileNavigationOpen] =
    React.useState(false);

  const navItems = React.useMemo(
    () => [
      {
        to: "/",
        title: "navigation:links.app",
      },
      {
        to: "/our-partners",
        title: "navigation:links.partners",
      },
      {
        to: "/blog",
        title: "navigation:links.blog",
      },
      {
        to: "/mission",
        title: "navigation:links.mission",
      },
    ],
    []
  );

  const handleOpenSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(true);
  }, []);

  const handleOpenMobileNav = React.useCallback(() => {
    setIsMobileNavigationOpen(true);
  }, []);

  const handleCloseMobileNav = React.useCallback(() => {
    setIsMobileNavigationOpen(false);
  }, []);

  return (
    <div className={classNames("flex items-center w-full h-20", className)}>
      <nav className="flex items-center h-20 font-headline container-7xl">
        <h1 className="text-lg sm:text-2xl md:text-4x font-headline">
          <Link to="/">
            <LogoType className="color-primary" />
          </Link>
        </h1>
        <ul className="flex-row items-center justify-end flex-grow flex-shrink-0 hidden pl-4 space-x-5 md:flex lg:space-x-6">
          {navItems.map((i) => (
            <li key={i.to}>
              <Link
                className="nav-button"
                to={i.to}
                activeClassName="active"
                partiallyActive={i.to !== "/"}
              >
                <span className="block">{t(i.title)}</span>
              </Link>
            </li>
          ))}
          <li>
            <a className="nav-button" href={`mailto:${mailContact}`}>
              <span>{t("navigation:links.contact")}</span>
            </a>
          </li>
          <div className="flex flex-row space-x-3">
            <LanguageSwitch />
            {downloadActive ? (
              <li>
                <button
                  onClick={() => {
                    setIsDownloadPopupOpen(true);
                  }}
                  className="button-green-filled !px-3 !py-1 lg:!px-4 lg:!py-2"
                >
                  {t("navigation:links.download")}
                </button>
              </li>
            ) : (
              <li>
                <button
                  onClick={handleOpenSignupPopup}
                  className="hidden button-green-filled md:block !px-3 !py-1 lg:!px-4 lg:!py-2"
                >
                  {t("navigation:links.signup")}
                </button>
              </li>
            )}
          </div>
        </ul>
        <div className="flex items-center ml-auto space-x-4 md:hidden">
          <div className="flex flex-row space-x-3">
            {downloadActive && (
              <a
                href={`/download${
                  utmTags && utmTags !== "" ? "?" : ""
                }${utmTags}`}
                className="button-green-filled !px-3 !py-1 lg:!px-4 lg:!py-2"
              >
                {t("navigation:links.download")}
              </a>
            )}
          </div>
          <button
            type="button"
            className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-lg"
            aria-label="Main menu"
            aria-haspopup="true"
            onClick={handleOpenMobileNav}
          >
            <svg
              className="w-6 h-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

        {showSignupFab && !downloadActive && (
          <FloatingSignupFab onClick={handleOpenSignupPopup} />
        )}

        <Transition
          show={isMobileNavigationOpen}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
        >
          <div className="shadow-md rounded-xl">
            <div
              className="overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
            >
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <h1 className="text-lg sm:text-2xl md:text-4xl text-primary md:pl-4 font-headline">
                    <Link to="/">
                      <LogoType className="color-primary" />
                    </Link>
                  </h1>
                </div>
                <div className="-mr-2">
                  <div className="flex flex-row items-center space-x-4 text-primary">
                    <LanguageSwitch onChange={handleCloseMobileNav} />
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-lg"
                      aria-label="Close menu"
                      onClick={handleCloseMobileNav}
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                {navItems.map((i) => (
                  <Link
                    key={i.to}
                    to={i.to}
                    className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-lg hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                    role="menuitem"
                    onClick={handleCloseMobileNav}
                  >
                    {t(i.title)}
                  </Link>
                ))}
                <a
                  href={`mailto:${mailContact}`}
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-lg hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={handleCloseMobileNav}
                >
                  {t("navigation:links.contact")}
                </a>
              </div>
              {!downloadActive && (
                <div>
                  <button
                    onClick={handleOpenSignupPopup}
                    className="block w-full px-5 py-3 font-medium tracking-wide text-center text-green-500 uppercase transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-green-600 focus:outline-none focus:bg-gray-100 focus:text-green-600"
                    role="menuitem"
                  >
                    {t("navigation:links.signup")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Transition>
      </nav>
    </div>
  );
};

export default Navigation;
