exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-agb-tsx": () => import("./../../../src/pages/app/agb.tsx" /* webpackChunkName: "component---src-pages-app-agb-tsx" */),
  "component---src-pages-app-datenschutz-tsx": () => import("./../../../src/pages/app/datenschutz.tsx" /* webpackChunkName: "component---src-pages-app-datenschutz-tsx" */),
  "component---src-pages-app-download-tsx": () => import("./../../../src/pages/_app/download.tsx" /* webpackChunkName: "component---src-pages-app-download-tsx" */),
  "component---src-pages-app-invite-tsx": () => import("./../../../src/pages/_app/invite.tsx" /* webpackChunkName: "component---src-pages-app-invite-tsx" */),
  "component---src-pages-awesome-tsx": () => import("./../../../src/pages/awesome.tsx" /* webpackChunkName: "component---src-pages-awesome-tsx" */),
  "component---src-pages-become-a-partner-tsx": () => import("./../../../src/pages/become-a-partner.tsx" /* webpackChunkName: "component---src-pages-become-a-partner-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-datenschutzerklaerung-tsx": () => import("./../../../src/pages/datenschutzerklaerung.tsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-go-challenge-tsx": () => import("./../../../src/pages/__/go/challenge.tsx" /* webpackChunkName: "component---src-pages-go-challenge-tsx" */),
  "component---src-pages-go-compensate-tsx": () => import("./../../../src/pages/__/go/compensate.tsx" /* webpackChunkName: "component---src-pages-go-compensate-tsx" */),
  "component---src-pages-go-delete-account-tsx": () => import("./../../../src/pages/__/go/deleteAccount.tsx" /* webpackChunkName: "component---src-pages-go-delete-account-tsx" */),
  "component---src-pages-go-delete-bank-connection-tsx": () => import("./../../../src/pages/__/go/deleteBankConnection.tsx" /* webpackChunkName: "component---src-pages-go-delete-bank-connection-tsx" */),
  "component---src-pages-go-faq-tsx": () => import("./../../../src/pages/__/go/faq.tsx" /* webpackChunkName: "component---src-pages-go-faq-tsx" */),
  "component---src-pages-go-habit-tsx": () => import("./../../../src/pages/__/go/habit.tsx" /* webpackChunkName: "component---src-pages-go-habit-tsx" */),
  "component---src-pages-go-impact-tsx": () => import("./../../../src/pages/__/go/impact.tsx" /* webpackChunkName: "component---src-pages-go-impact-tsx" */),
  "component---src-pages-go-openbanking-bank-not-found-tsx": () => import("./../../../src/pages/__/go/openbankingBankNotFound.tsx" /* webpackChunkName: "component---src-pages-go-openbanking-bank-not-found-tsx" */),
  "component---src-pages-go-openbanking-cancel-tink-tsx": () => import("./../../../src/pages/__/go/openbankingCancelTink.tsx" /* webpackChunkName: "component---src-pages-go-openbanking-cancel-tink-tsx" */),
  "component---src-pages-go-openbanking-data-usage-tsx": () => import("./../../../src/pages/__/go/openbankingDataUsage.tsx" /* webpackChunkName: "component---src-pages-go-openbanking-data-usage-tsx" */),
  "component---src-pages-go-rating-tsx": () => import("./../../../src/pages/__/go/rating.tsx" /* webpackChunkName: "component---src-pages-go-rating-tsx" */),
  "component---src-pages-go-security-tsx": () => import("./../../../src/pages/__/go/security.tsx" /* webpackChunkName: "component---src-pages-go-security-tsx" */),
  "component---src-pages-go-survey-tsx": () => import("./../../../src/pages/__/go/survey.tsx" /* webpackChunkName: "component---src-pages-go-survey-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invitation-tsx": () => import("./../../../src/pages/invitation.tsx" /* webpackChunkName: "component---src-pages-invitation-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-openbanking-signup-tsx": () => import("./../../../src/pages/openbanking_signup.tsx" /* webpackChunkName: "component---src-pages-openbanking-signup-tsx" */),
  "component---src-pages-our-partners-bonuses-tsx": () => import("./../../../src/pages/our-partners/bonuses.tsx" /* webpackChunkName: "component---src-pages-our-partners-bonuses-tsx" */),
  "component---src-pages-our-partners-discounts-tsx": () => import("./../../../src/pages/our-partners/discounts.tsx" /* webpackChunkName: "component---src-pages-our-partners-discounts-tsx" */),
  "component---src-pages-our-partners-index-tsx": () => import("./../../../src/pages/our-partners/index.tsx" /* webpackChunkName: "component---src-pages-our-partners-index-tsx" */),
  "component---src-pages-our-partners-partners-tsx": () => import("./../../../src/pages/our-partners/partners.tsx" /* webpackChunkName: "component---src-pages-our-partners-partners-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-in-app-post-tsx": () => import("./../../../src/templates/inAppPost.tsx" /* webpackChunkName: "component---src-templates-in-app-post-tsx" */),
  "component---src-templates-partner-detail-tsx": () => import("./../../../src/templates/partnerDetail.tsx" /* webpackChunkName: "component---src-templates-partner-detail-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-reward-detail-tsx": () => import("./../../../src/templates/rewardDetail.tsx" /* webpackChunkName: "component---src-templates-reward-detail-tsx" */)
}

