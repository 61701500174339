import { useEffect, useState } from "react";

const getItem = (key: string, defaultValue: string) =>
  document.cookie.split("; ").reduce((value, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : value;
  }, defaultValue);

const setItem = (key: string, value: string, numberOfDays: number) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};

export default function useCookieState(
  name: string,
  defaultValue: string
): [string | null, (v: string) => void] {
  const [cookie, setCookie] = useState<string | null>(null);

  useEffect(() => {
    setCookie(getItem(name, defaultValue));
  }, [setCookie, name, defaultValue]);

  function update(value: string) {
    setItem(name, value, 356);
    setCookie(value);
  }

  return [cookie, update];
}
