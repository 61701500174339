// import Headroom from "headroom.js";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import classNames from "classnames";
import { stringify } from "query-string";
import React from "react";
import {
  encodeQueryParams,
  StringParam,
  useQueryParams,
} from "use-query-params";
import ConsentDialog from "../components/ConsentDialoag";
import DownlaodQRCodeModal from "../components/DownloadQRCodeDialog";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import ResponsiveSizes from "../components/ResponsiveSizes";
import SEO from "../components/SEO";
import SignupDialog from "../components/SignupDialog";
import { useGlobalState } from "../state/globalState";
import { IPageContext } from "../types/gatsby";

const HEADER_FIX_OFFSET = -100;
const HEADER_PIN_OFFSET = -700;

export interface LayoutProps {
  hideSignupFab?: boolean;
  showSignupFab?: boolean;
  pageContext: IPageContext;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  showSignupFab,
  pageContext,
}) => {
  const isAppPage = React.useMemo(
    () =>
      pageContext.i18n.path.includes("/__/go/") ||
      pageContext.i18n.path.includes("/justthepost/"),
    []
  );

  const [utmQueryParams] = useQueryParams({
    ref: StringParam,
    utm_id: StringParam,
    utm_medium: StringParam,
    utm_source: StringParam,
    utm_campaign: StringParam,
    utm_content: StringParam,
    utm_term: StringParam,
  });

  const [utmTags, setUtmTags] = useGlobalState("utmTags");
  const [isDownloadPopupOpen, setIsDownloadPopupOpen] = useGlobalState(
    "isDownloadPopupOpen"
  );
  const [isSignupPopupOpen, setIsSignupPopupOpen] =
    useGlobalState("isSignupPopupOpen");

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    if (utmQueryParams && !utmTags) {
      const utmTagsEncoded = encodeQueryParams(
        {
          ref: StringParam,
          utm_id: StringParam,
          utm_medium: StringParam,
          utm_source: StringParam,
          utm_campaign: StringParam,
          utm_content: StringParam,
          utm_term: StringParam,
        },
        utmQueryParams
      );
      const utmTagString = stringify(utmTagsEncoded);
      setUtmTags(utmTagString);
    }
  }, [utmQueryParams]);

  useScrollPosition(
    ({ currPos }) => {
      const top = currPos.y >= 0;
      const prepareHeader = currPos.y < HEADER_FIX_OFFSET;
      const pinHeader = currPos.y < HEADER_PIN_OFFSET;

      if (top && headerOptions.pinState !== "top") {
        setHeaderOptions({ ...headerOptions, pinState: "top" });
      }

      if (prepareHeader && headerOptions.pinState === "top") {
        setHeaderOptions({ ...headerOptions, pinState: "prepared" });
      } else if (
        !prepareHeader &&
        (headerOptions.pinState === "prepared" ||
          headerOptions.pinState === "unpinned")
      ) {
        setHeaderOptions({ ...headerOptions, pinState: "top" });
      }

      if (
        pinHeader &&
        (headerOptions.pinState === "prepared" ||
          headerOptions.pinState === "unpinned")
      ) {
        setHeaderOptions({ ...headerOptions, pinState: "pinned" });
      } else if (!pinHeader && headerOptions.pinState === "pinned") {
        setHeaderOptions({ ...headerOptions, pinState: "unpinned" });
      }
    },
    [headerOptions]
  );

  const handleCloseSignupPopup = React.useCallback(() => {
    setIsSignupPopupOpen(false);
  }, []);

  return (
    <div className="h-full min-h-screen font-sans">
      <SEO />
      <div className="flex flex-col h-full min-h-screen">
        <div
          className={classNames([
            "header",
            headerOptions.colorScheme,
            (isAppPage || headerOptions.hidden) && "hidden",
            `header--${headerOptions.pinState}`,
          ])}
        >
          <Navigation showSignupFab={!!showSignupFab} />
        </div>
        <main className="flex-grow overflow-hidden">{children}</main>
        {!isAppPage && !headerOptions.hidden && <Footer />}
      </div>
      <SignupDialog
        visible={isSignupPopupOpen}
        requestClose={handleCloseSignupPopup}
      />
      {!isAppPage && <ConsentDialog />}
      <ResponsiveSizes />
      <DownlaodQRCodeModal
        isOpen={isDownloadPopupOpen}
        onClose={() => setIsDownloadPopupOpen(false)}
      />
    </div>
  );
};

export default Layout;
