import { DependencyList, EffectCallback, RefObject, useEffect } from "react";

export default function useClickoutside<T extends HTMLElement>(
  enabled: boolean,
  ref: RefObject<T>,
  effect: EffectCallback
) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    function handleClickOutside(event: Event) {
      if (
        ref.current &&
        event.target &&
        !ref.current.contains(event.target as Node)
      ) {
        effect();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, enabled]);
}
