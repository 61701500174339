import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { usePopper } from "react-popper";

const popperOptions = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 8],
      },
    },
  ],
};

export interface LanguageSwitchProps {
  onChange?: (lng: string) => void;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ onChange }) => {
  const { languages, originalPath, language } = useI18next();

  const [referenceElement, setReferenceElement] =
    React.useState<HTMLButtonElement | null>();

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>();

  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    popperOptions
  );

  const handleLanguageClicked = (lng: string) => {
    if (onChange) {
      onChange(lng);
    }
  };

  return (
    <div className="relative">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="flex flex-row items-center px-3 py-1 space-x-1 border border-opacity-75 rounded-full lg:px-4 lg:py-2">
              <span className="block mt-px font-bold tracking-widest text-xxs">
                {language.toUpperCase()}
              </span>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.07745 0.744078C1.40289 0.418641 1.93053 0.418641 2.25596 0.744078L5.00004 3.48816L7.74412 0.744078C8.06955 0.418641 8.59719 0.418641 8.92263 0.744078C9.24807 1.06951 9.24807 1.59715 8.92263 1.92259L5.5893 5.25592C5.26386 5.58136 4.73622 5.58136 4.41079 5.25592L1.07745 1.92259C0.752015 1.59715 0.752015 1.06951 1.07745 0.744078Z"
                  fill="currentColor"
                />
              </svg>
            </Menu.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items className="absolute right-0 flex flex-col p-4 mt-2 space-y-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {languages &&
                  languages.map((lng) => (
                    <Menu.Item key={lng}>
                      <Link
                        className={classNames([
                          "p-2 rounded hover:bg-gray-100 text-primary text-center block font-bold uppercase text-sm",
                          language === lng ? "bg-gray-200" : "bg-white",
                        ])}
                        to={originalPath}
                        language={lng}
                      >
                        {lng.toUpperCase()}
                      </Link>
                    </Menu.Item>
                  ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

export default LanguageSwitch;
