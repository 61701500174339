import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { Helmet } from "react-helmet";
import ogimage from "../../static/ogimage.png";
import useSiteMetadata from "../hooks/useSiteMetadata";

const SEO = () => {
  const {
    title,
    description,
    url,
    appleItunesAppId,
    downloadActive,
    keywords,
  } = useSiteMetadata();

  const { language } = useI18next();

  return (
    <Helmet>
      <html lang={language} />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={url + ogimage} />
      {/*<meta property="og:image:width" content="1600" />*/}
      {/*<meta property="og:image:height" content="900" />*/}
      <meta property="og:site_name" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={url + ogimage} />

      <meta
        name="facebook-domain-verification"
        content="t8bdwqizhy6x84vb37y548rvneckjv"
      />

      {downloadActive && (
        <meta
          property="apple-itunes-app"
          content={`app-id=${appleItunesAppId} app-argument=${url}`}
        />
      )}
      <meta property="keywords" content={keywords.join(",")} />
      <title>{title}</title>
    </Helmet>
  );
};

export default SEO;
