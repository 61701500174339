import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import QRCode from "react-qr-code";
import { useGlobalState } from "../state/globalState";

export interface DownlaodQRCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DownloadQRCodeModal: React.FC<DownlaodQRCodeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(["common"]);

  const [utmTags] = useGlobalState("utmTags");

  const downloadURL = React.useMemo(
    () =>
      utmTags
        ? `https://plan3t.one/download?${utmTags}`
        : "https://plan3t.one/download",
    [utmTags]
  );

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative flex flex-col items-center p-12 mx-auto bg-white w-sm rounded-xl">
            <h2 className="mt-6 mb-12 text-xl text-center w-96">
              {t("common:download.qr_code")}
            </h2>
            <div className="p-4 border border-gray-200 rounded-lg">
              <QRCode value={downloadURL} size={160} />
            </div>
            <button
              type="button"
              aria-label="Close download popup"
              className="absolute top-4 right-4"
              onClick={onClose}
            >
              <svg
                className="w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DownloadQRCodeModal;
