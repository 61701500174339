import React from "react";

export interface LogoTypeProps extends React.HTMLAttributes<HTMLDivElement> {}

const LogoType: React.FC<LogoTypeProps> = ({ className, style }) => {
  return (
    <div className={className} style={style}>
      <svg
        width="120"
        height="30"
        viewBox="0 0 100 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 5.44077H7.93949C11.0198 5.44077 12.8175 6.97151 12.8175 9.82549C12.8175 12.638 11.0166 14.2447 7.93949 14.2447H3.23295V19.0279H0V5.44077ZM3.23295 8.27205V11.4095H7.63334C8.87673 11.4095 9.48902 10.7972 9.48902 9.8435C9.48902 8.82562 8.8963 8.27753 7.63334 8.27753L3.23295 8.27205Z"
          fill="currentColor"
        />
        <path
          d="M25.2466 19.024H15.1516V5.44077H18.4041V16.1544H25.2466V19.024Z"
          fill="currentColor"
        />
        <path
          d="M37.8457 16.6704H31.379L30.365 19.024H27.0914L33.0421 5.42274H36.3705L42.3017 19.0256H38.8565L37.8457 16.6704ZM36.6782 13.9573L34.6119 9.15524L32.5456 13.9573H36.6782Z"
          fill="currentColor"
        />
        <path
          d="M57.6842 19.0624H55.044L47.6401 9.66655V19.024H44.4643V5.44077H48.1537L54.582 13.7631V5.44077H57.6811L57.6842 19.0624Z"
          fill="currentColor"
        />
        <path
          d="M86.4739 5.44077H100V8.27205H94.8534V19.024H91.6205V8.27205H86.4739V5.44077Z"
          fill="currentColor"
        />
        <path
          d="M72.1499 0.333344C69.8882 0.346523 67.6768 1.00241 65.7735 2.22452C63.8703 3.44664 62.3538 5.18462 61.4007 7.2358C60.4476 9.28699 60.0973 11.5669 60.3906 13.8096C60.6838 16.0523 61.6086 18.1654 63.0571 19.9025L60.6157 22.3102C60.4081 22.515 60.2903 22.7938 60.2882 23.0855C60.2861 23.3771 60.4 23.6576 60.6048 23.8652C60.8095 24.0729 61.0884 24.1907 61.38 24.1928C61.6716 24.1948 61.9521 24.081 62.1598 23.8762L64.6152 21.4552C66.3596 22.8984 68.4786 23.8155 70.7247 24.0993C72.9709 24.3831 75.2514 24.022 77.2999 23.0581C79.3484 22.0942 81.0804 20.5673 82.2935 18.6557C83.5066 16.7442 84.1508 14.5269 84.1508 12.2629V0.333344H72.1499Z"
          fill="#10B981"
        />
        <path
          d="M78.976 14.8703C78.976 13.2621 77.9213 12.4149 76.1228 11.9482V11.913C77.5933 11.4291 78.561 10.6336 78.561 9.2328C78.561 7.31214 76.7985 5.8918 73.6595 5.61618V3.84663H71.457V5.61853C69.635 5.79314 67.9844 6.40152 66.8374 7.14066L67.5467 9.28448C68.982 8.42319 70.4516 7.9534 72.4412 7.9534C74.6547 7.9534 75.8127 8.59388 75.8127 9.4583C75.8112 10.3556 74.7753 10.982 70.9175 10.982V12.9708C74.9812 12.9708 76.14 13.5588 76.14 14.6996C76.14 15.7371 74.9123 16.5851 72.4741 16.5851C70.7172 16.6035 68.9868 16.1561 67.4591 15.2884L66.7841 17.5192C67.9476 18.191 69.4729 18.7453 71.457 18.8855V20.6448H73.6595V18.8737C77.3059 18.602 78.976 17.076 78.976 14.8703Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default LogoType;
