import { Transition } from "@headlessui/react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useRef } from "react";
import useClickoutside from "../hooks/useClickoutside";
import Portal from "./Portal";
import SignupForm from "./SignupForm";

export default function SignupDialog(props: {
  visible: boolean;
  requestClose: () => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(["common", "sign_up_form"]);

  useClickoutside(props.visible, ref, props.requestClose);

  return (
    <Portal>
      <Transition show={props.visible}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              className="fixed inset-0 transition-opacity"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </Transition.Child>
            {/*This element is to trick the browser into centering the modal contents.*/}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
            &#8203;
            <Transition.Child
              className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white shadow-xl rounded-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal
              aria-labelledby="signupdialog-headline"
            >
              <div
                className="flex flex-col px-4 pt-5 pb-4 space-y-6 bg-white sm:p-6 sm:pb-4"
                ref={ref}
              >
                <h1
                  className="font-bold font-headline"
                  id="signupdialog-headline"
                >
                  {t("sign_up_form:title")}
                </h1>
                <button
                  className="absolute top-0 right-0 px-4 text-sm font-headline"
                  onClick={props.requestClose}
                >
                  {t("sign_up_form:later")}
                </button>
                <SignupForm />
                <span className="px-4 text-xs tracking-wide sm:px-0 font-headline">
                  {t("common:privacy.description")}
                  &nbsp;
                  <Link to="/datenschutzerklaerung" className="text-indigo-400">
                    {t("common:privacy.privacy_policy")}
                  </Link>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </Portal>
  );
}
